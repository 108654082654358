import React, { useState } from 'react';
import BlogSingle from '../BlogSingle'
import {Link} from 'react-router-dom'
import blg1 from '../../images/blog/blog1/1.jpg'
import blg2 from '../../images/blog/blog1/2.jpg'
import blg3 from '../../images/blog/blog1/3.jpg'
import author from '../../images/blog/blog1/author.jpg'
import author2 from '../../images/blog/blog1/author2.jpg'
import author3 from '../../images/blog/blog1/author3.jpg'


const blog = [
    {
        Id:"1",
        heading:"Everything is easy when you think it easy at all.",
        bImg1:blg1,
        author:author,
        authorName:"Warner",
        date:"April 09,2021",
        des:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now.'
    },
    {
        Id:"2",
        heading:"How to estublish a team with a great way for you?",
        bImg1:blg2,
        author:author2,
        authorName:"Miller",
        date:"April 11,2021",
        des:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now.'
    },
    {
        Id:"3",
        heading:"A great way to establish as 3D designer for you.",
        bImg1:blg3,
        author:author3,
        authorName:"alia",
        date:"April 14,2021",
        des:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now.'
    },
]



const BlogSection = () => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state,setState] = useState({
        
    })

    const handleClickOpen = (item) =>{
        setOpen(true);
        setState(item)
    }

    return (
        <section id="blog" className="blog-section section-padding">
            </section>
    );
}
export default BlogSection;