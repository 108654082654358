import React, { Component } from 'react';

import {Link} from 'react-router-dom'

class PricingPlan extends Component {
    render() {
        return (
            <section className="pricing-section section-padding">
                
            </section>
        );
    }
}

export default PricingPlan;